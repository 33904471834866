import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Seo from '../components/seo'
import Container from '../components/container'
import * as styles from './products.module.css'

class ProductIndex extends React.Component {
  render() {
    let products = get(this, 'props.data.allContentfulProduct.nodes')
    products = products.sort((a, b) => a.order - b.order)

    return (
      <Container className={styles.product}>
        <Seo title="Produits" description="Découvrer nos produits à base de tofu." />
        <h1>Nos produits</h1>
        <ul className={styles.productList}>
          {products.map((product) => {
            return (
              <li key={product.slug}>
                <Link to={`/produits/${product.slug}`} className={styles.link}>
                  <GatsbyImage alt={product.title} image={product.heroImage.gatsbyImage} objectFit='cover' />
                  <div className={styles.title}>{product.title}</div>
                </Link>
              </li>
            )
          })}
        </ul>
      </Container>
    )
  }
}

export default ProductIndex

export const pageQuery = graphql`
  query ProductIndexQuery {
    allContentfulProduct {
      nodes {
        title
        slug
        order
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 321
          )
        }
        description {
          raw
        }
      }
    }
  }
`
